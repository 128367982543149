import React from 'react';
import styled from '@emotion/styled';

import Layout from '../components/common/layout';
import EmailForm from '../components/contact/form';
import Hero from '../components/common/hero';

const Wrapper = styled.div`
  h2 {
    margin: 16px 0;
  }
  margin: 0 auto;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    margin-top: 20px;
    :not(:first-child) :not(:last-child) {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
`;

const BadgesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  strong {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }
`;

const Badge = styled.div`
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #233049;
  width: 350px;
  max-width: 350px;
  flex: 1 1 100px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  padding: 20px 40px;
  margin: 10px 0;
`;

const About = () => {
  return (
    <Layout>
      <Hero isBig={false}>
        <h1 style={{ color: '#ffffff', paddingRight: '60px' }}>Contactos</h1>
      </Hero>
      <div className="content">
        <Wrapper>
          <FlexWrapper>
            <div>
              <h2>A Nossa Equipa</h2>
              <BadgesWrapper>
                <Badge>
                  <strong>Henrique Pereira</strong>
                  <br />
                  <p>Eng. Eletrotécnico</p>
                </Badge>
                <Badge>
                  <strong>José Rato</strong>
                  <br />
                  <p>Eng. Eletrotécnico</p>
                </Badge>
              </BadgesWrapper>
            </div>
            <div style={{ maxWidth: '350px' }}>
              <h2>Contacte-nos</h2>
              <EmailForm />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <h2>Onde Estamos</h2>
              <figure style={{ marginTop: '20px' }}>
                <iframe
                  title="map"
                  src="https://maps.google.com/maps?q=Estrada%20dos%20pinheiros%2C%2090%2C%20Marrazes&amp;t=m&amp;z=12&amp;output=embed&amp;iwloc=near"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  aria-label="Estrada dos pinheiros, 90, Marrazes"
                  frameBorder="0"
                />
              </figure>
            </div>
          </FlexWrapper>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default About;
