import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import styled from '@emotion/styled';

const FeedbackWrapper = styled.div`
  font-size: 16px;
  margin-top: 8px;
  text-align: center;
`;

export const MaterialForm = props => {
  const {
    values: { name, email, message },
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    isSubmitting,
    status,
  } = props;

  console.log({ status });

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        error={touched.name && Boolean(errors.name)}
        fullWidth
        helperText={touched.name ? errors.name : ''}
        id="name"
        label="Nome"
        name="name"
        onChange={change.bind(null, 'name')}
        value={name}
      />
      <TextField
        error={touched.email && Boolean(errors.email)}
        fullWidth
        helperText={touched.email ? errors.email : ''}
        id="email"
        label="Email"
        name="email"
        onChange={change.bind(null, 'email')}
        value={email}
      />
      <TextField
        error={touched.message && Boolean(errors.message)}
        fullWidth
        helperText={touched.message ? errors.message : ''}
        id="standard-multiline-flexible"
        label="Mensagem"
        multiline
        name="message"
        onChange={change.bind(null, 'messsage')}
        rows={3}
        type="text"
        value={message}
      />
      <Button
        color="primary"
        disabled={isSubmitting}
        fullWidth
        type="submit"
        variant="contained"
      >
        Enviar Mensagem
      </Button>
      {errors?.response && status !== 1 && (
        <FeedbackWrapper style={{ color: 'red' }}>Ocorreu um erro tente novamente</FeedbackWrapper>
      )}
      {!errors.response && status === 1 && (
        <FeedbackWrapper style={{ color: 'green' }}>Mensagem enviada com sucesso!</FeedbackWrapper>
      )}
    </form>
  );
};

export default MaterialForm;
