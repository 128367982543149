import React, { useState } from 'react';

import { Formik } from 'formik';

import * as Yup from 'yup';
import styled from '@emotion/styled';
import MaterialForm from './material-form';

const Wrapper = styled.div`
  align-items: center;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  button {
    margin-top: 20px;
  }
`;

const SuccessWrapper = styled.div`
  color: green;
`;


const EmailForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const initialValues = {
    name: '',
    message: '',
    email: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(30, 'Não pode conter mais de 30 caracteres.')
      .required('Campo Obrigatório'),

    message: Yup.string()
      .max(1000, 'Não pode conter mais de 1000 caracteres.')
      .required('Campo Obrigatório'),

    email: Yup.string()
      .email('Email inválido')
      .required('Campo Obrigatório'),
  });

  const endpoints = {
    contact: '/.netlify/functions/sendEmail',
  };

  const handleSubmit = (values, actions) => {
    const setResponseError = () => actions.setErrors({ response: 'error' });
    actions.setSubmitting(true);

    const axios = require('axios');

    axios
      .post('https://getform.io/f/56a7e1a9-fac2-479d-8ae8-f4e0d9227a5c', {
        Nome: values.name, Email: values.email, Mensagem: values.message,
      })
      .then(() => {
        actions.setErrors({});
        actions.setStatus(1);
      })
      .catch(() => {
        actions.setSubmitting(false);
        setResponseError();
      });
  };


  return (
    <Wrapper>
      {success ? (
        <SuccessWrapper>Mensagem enviada com sucesso!</SuccessWrapper>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          render={props => (
            <MaterialForm
              {...props}
              value={{ email, name, message }}
              setValues={{ setEmail, setName, setMessage }}
            />
          )}
        />
      )}
    </Wrapper>
  );
};

export default EmailForm;
